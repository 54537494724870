import Service from "./config/ConfigRepositoryAuth";

export default {
  async create(antenna) {
    return await Service.post(`antenna`, antenna);
  },
  async update(idAntenna, antenna) {
    return await Service.put(`antenna/${idAntenna}`, antenna);
  },
  async getAll() {
    return await Service.get(`antenna`);
  },
  async delete(idAntenna) {
    return await Service.delete(`antenna/${idAntenna}`);
  },
};
