<template>
  <v-container>
    <div
      class="mt-5 mb-5 d-flex justify-space-between align-center white px-5 py-5 rounded-lg"
    >
      <h1>Antenas</h1>
      <v-btn @click="newAntenna" class="px-5" color="primary" depressed
        >AGREGAR</v-btn
      >
    </div>

    <v-row>
      <v-col cols="3" v-for="antenna in antennas" :key="antenna._id">
        <v-card
          class="py-6 px-3"
          @click="$emit('changeComponent', 'DetailsAntenna', antenna._id)"
          color="primary"
          dark
          @contextmenu="show($event, antenna)"
        >
          <span>Nombre</span>
          <p class="text-h5 font-weight-bold text-uppercase">
            {{ antenna.antennaName }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-progress-linear v-if="isLoading" indeterminate />

    <p v-if="antennas.length === 0" class="text-center mt-2">No hay resultados</p>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="dialog = true">
          <v-list-item-content>
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="eliminarAntena()">
          <v-list-item-content>
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <new-antenna
      v-if="dialog"
      :dialog="dialog"
      :antennaEdit="antennaEdit"
      :isEdit="isEdit"
      @cancel="dialog = false"
      @refreshView="refreshView"
    ></new-antenna>
  </v-container>
</template>

<script>
// Domains
import Antenna from "../../domain/Antennas";

export default {
  name: "ListAntennas",
  mounted() {
    this.getAntennas();
  },
  components: {
    NewAntenna: () => import("./NewAntenna"),
  },
  data: () =>
  ({
    isLoading: true,
    dialog: false,
    antennas: [],
    showMenu: false,
    x: 0,
    y: 0,
    antennaEdit: {},
    isEdit: false,
  }),
  methods: {
    newAntenna() {
      this.isEdit = false;
      this.dialog = true;
    },
    show(e, antenna) {
      this.antennaEdit = Object.assign({}, antenna);
      this.isEdit = true;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async refreshView() {
      this.isEdit = false;
      await this.getAntennas();
      this.dialog = false;
    },
    async getAntennas() {
      try {
        this.antennas = await Antenna.getAll();
      } catch (error) {
        console.log(error);
      }finally{
        this.isLoading = false
      }
    },
    antenaDetail(id) {
      console.log(id);
    },
    async eliminarAntena(){
      try {
        await Antenna.delete(this.antennaEdit._id)
        this.getAntennas()
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>