import AntennaRepository from "../repository/Antenna.repository";

export default class Antenna {
  static async getAll() {
    return new Promise((resolve, reject) => {
        AntennaRepository.getAll()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async create(antenna) {
    return new Promise((resolve, reject) => {
        AntennaRepository.create(antenna)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }


  static async update(idAntenna, antenna) {
    return new Promise((resolve, reject) => {
        AntennaRepository.update(idAntenna, antenna)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  static async delete(idAntenna) {
    return new Promise((resolve, reject) => {
        AntennaRepository.delete(idAntenna)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  }
}
